// Store
import store from "@/store/index";

export interface AdministratorPlayersProfileTipsListFormCreate {
  user_id: string | string[];
  organization_id: string;
  content: {
    message: string;
  };
}

export class AdministratorPlayersProfileTipsListFormCreate implements AdministratorPlayersProfileTipsListFormCreate {
  user_id: string | string[];
  organization_id: string;
  content: {
    message: string;
  };

  constructor() {
    this.user_id = "";
    this.organization_id = store.getters.getOrganizationMember.organization_id ?? "";
    this.content = {
      message: "",
    };
  }
}