
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AdministratorPlayersProfileTipsListFormCreate} from "@/models/app/administrator/players/profile/tips/list/form/create";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElButton,
} from "element-plus";
import {Plus} from "@element-plus/icons-vue";

// Services
import {postRequest} from "@/services/api/request";

@Options({
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElButton,
    Plus,
  },
})
export default class AdministratorPlayersProfileTipsListFormCreateIndexVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  formData: AdministratorPlayersProfileTipsListFormCreate = new AdministratorPlayersProfileTipsListFormCreate();
  formDataRules = {
    content: {
      message: [
        {
          required: true,
          message: "Polje nije popunjeno",
          trigger: "blur",
        },
      ],
    },
  };

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/common/tips/create",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getTips');
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  resetFormData(): void {
    this.formData = new AdministratorPlayersProfileTipsListFormCreate();
    this.formData.user_id = this.$route.params.id;
  }

  created(): void {
    this.formData.user_id = this.$route.params.id;
  }
}
