import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b0021172"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navigation" }
const _hoisted_2 = { class: "navigation-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'AppAdministratorPlayersProfileRootIndexVue', params: { id: _ctx.$route.params.id } }
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Profil ")
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'AppAdministratorPlayersProfileStatisticsIndexVue', params: { id: _ctx.$route.params.id } }
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Statistika ")
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'AppAdministratorPlayersProfileMatchesIndexVue', params: { id: _ctx.$route.params.id } }
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Utakmice ")
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'AppAdministratorPlayersProfileTrainingsIndexVue', params: { id: _ctx.$route.params.id } }
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Treninzi ")
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'AppAdministratorPlayersProfileDiagnosticsIndexVue', params: { id: _ctx.$route.params.id } }
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Dijagnostika ")
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'AppAdministratorPlayersProfileHealthRecordsIndexVue', params: { id: _ctx.$route.params.id } }
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Zdravlje ")
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'AppAdministratorPlayersProfileTipsIndexVue', params: { id: _ctx.$route.params.id } }
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Savjeti ")
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'AppAdministratorPlayersProfileNotesIndexVue', params: { id: _ctx.$route.params.id } }
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Dnevnik ")
            ]),
            _: 1
          }, 8, ["to"])
        ])
      ])
    ])
  ]))
}